:root {
  --dl-color-gray-500: #595959;
  --dl-color-gray-700: #999999;
  --dl-color-gray-900: #D9D9D9;
  --dl-size-size-large: 144px;
  --dl-size-size-small: 48px;
  --dl-color-danger-300: #A22020;
  --dl-color-danger-500: #BF2626;
  --dl-color-danger-700: #E14747;
  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #FFFFFF;
  --dl-size-size-medium: 96px;
  --dl-size-size-xlarge: 192px;
  --dl-size-size-xsmall: 16px;
  --dl-space-space-unit: 16px;
  --dl-color-primary-tea: #c5bbacff;
  --dl-color-success-300: #199033;
  --dl-color-success-500: #32A94C;
  --dl-color-success-700: #4CC366;
  --dl-size-size-xxlarge: 288px;
  --dl-size-size-maxwidth: 1400px;
  --dl-radius-radius-round: 50%;
  --dl-color-primary-bazaar: #8f7e7cff;
  --dl-space-space-halfunit: 8px;
  --dl-space-space-sixunits: 96px;
  --dl-space-space-twounits: 32px;
  --dl-color-primary-myblack: #0d0d12ff;
  --dl-color-primary-mygreen: #2b6362ff;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-fiveunits: 80px;
  --dl-space-space-fourunits: 64px;
  --dl-color-primary-whiteish: #eae1d5ff;
  --dl-space-space-threeunits: 48px;
  --dl-color-primary-submarine: #859e9eff;
  --dl-color-primary-cleanslate: #292a2aff;
  --dl-space-space-oneandhalfunits: 24px;
}
.button {
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  padding: 0.5rem 1rem;
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: 40px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  background-color: #0000F5;
}
.button:hover {
  opacity: 0.7;
}
.input {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.textarea {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}
.list-item {
  display: list-item;
}
.teleport-show {
  display: flex !important;
  transform: translateX(0%) !important;
}
.slide-title {
  color: white;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  transition: 0.3s;
  align-items: flex-start;
  font-weight: 500;
  border-color: transparent;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: space-between;
  border-bottom-width: 2px;
}
.slide-title:hover {
  opacity: 0.5;
}
.slide-title-active {
  border-color: #0000F5;
}
.banner {
  cursor: pointer;
  margin: 5px;
  display: flex;
  max-width: auto;
  max-height: 400px;
  align-items: center;
  border-width: 0px;
  flex-direction: row;
  background-color: #0000F5;
}
.banner-smaller {
  width: 620px;
  height: 460px;
}
.swiper-pagination {
  cursor: grab;
  z-index: 10;
  list-style: none;
  text-align: center;
}
.swiper-pagination-bullet {
  width: 12px;
  cursor: pointer;
  height: 12px;
  display: inline-block;
  transition: width .25s ease;
  border-radius: 12px;
  background-color: ef9911;
}
.swiper-pagination-bullet-active {
  width: 45px;
  opacity: 1 !important;
}
.objective {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 280px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.value {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
}
.accordion {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: baseline;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
  border-bottom-width: 1px;
}
.accordion-content {
  color: #7C7C80;
  width: 100%;
  display: none;
  max-width: 560px;
  line-height: 24px;
}
.accordion-active {
  display: flex;
}


.blaze-track-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.blaze-track {
  width: auto;
  cursor: grabbing;
  height: 300px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}


.slider {
  -slides-to-show: 3;
}

.fixed-navbar {
  position: fixed;
  animation: slideInDown 1s both;
}
.navbar {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.covert-art {
  width: 100%;
  height: 100%;
}
.text-margin {
  margin-bottom: 4px;
}
.hover-gradient {
  width: 100%;
  bottom: 0px;
  height: 100%;
  display: none;
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.hover-gradient:hover {
  background-image: linear-gradient(0deg, rgba(48, 48, 48, 0.61) 0.00%,rgb(28, 27, 27) 100.00%);
}
.text-padding {
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  padding-bottom: 30px;
}
.AArtistName {
  font-size: 16px;
  font-family: "Oxygen";
  font-weight: 700;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.dnu3 {
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.ARole {
  font-size: 14px;
  font-family: Inter;
  font-weight: 300;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.dnu4 {
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.dnu5 {
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.ASongTitle {
  font-size: 16px;
  font-family: "Tahoma";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.dnu2 {
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.dnu1 {
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.GreenHead {
  font-size: 40px;
  font-family: Source Code Pro;
  font-weight: 700;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.GreenHead1 {
  font-size: 40px;
  font-family: Source Code Pro;
  font-weight: 700;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.Content {
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.Bigheader {
  font-size: 100px;
  font-family: Inter;
  font-weight: 500;
  text-transform: none;
  text-decoration: none;
}


@media(max-width: 991px) {
  .banner {
    flex-direction: column;
  }
  .swiper-pagination-bullet {
    opacity: 0.5;
    background-color: #ffffff;
  }
  .objective {
    flex: 1;
  }
  .accordion {
    gap: var(--dl-space-space-unit);
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .accordion-content {
    font-size: 14px;
    line-height: 21px;
  }
}
@media(max-width: 767px) {
  .objective {
    gap: var(--dl-space-space-unit);
    max-width: 100%;
  }
  .value {
    padding-top: 0px;
    padding-bottom: var(--dl-space-space-unit);
  }
  .navbar {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .button {
    width: 100%;
  }
  .navbar {
    padding: var(--dl-space-space-unit);
  }
}
