.component1-container {
  display: flex;
  position: relative;
}
@media(max-width: 479px) {
  .component1-text10 {
    width: 80%;
    align-self: flex-start;
  }
  .component1-text11 {
    color: var(--dl-color-primary-submarine);
    font-family: Source Code Pro;
    font-weight: 700;
  }
  .component1-text12 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text13 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text14 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text15 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text16 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text17 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text18 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text19 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text20 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text21 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text22 {
    color: var(--dl-color-primary-submarine);
    font-family: Source Code Pro;
    font-weight: 700;
  }
  .component1-text23 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text24 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text25 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text26 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text27 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text28 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text29 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text30 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text31 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text32 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text33 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text34 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text35 {
    color: var(--dl-color-primary-submarine);
    font-family: Source Code Pro;
    font-weight: 700;
  }
  .component1-text36 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text37 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text38 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text39 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text40 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text41 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text42 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text43 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text44 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text45 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text46 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text47 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text48 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text49 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text50 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text51 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text52 {
    color: var(--dl-color-primary-submarine);
    font-family: Source Code Pro;
    font-weight: 700;
  }
  .component1-text53 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text54 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text55 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text56 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text57 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text58 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text59 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text60 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text61 {
    color: var(--dl-color-primary-submarine);
    font-family: Source Code Pro;
    font-weight: 700;
  }
  .component1-text62 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text63 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text64 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text65 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text66 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text67 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text68 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text69 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text70 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text71 {
    color: var(--dl-color-primary-submarine);
    font-family: Source Code Pro;
    font-weight: 700;
  }
  .component1-text72 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text73 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text74 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text75 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text76 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text77 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text78 {
    color: var(--dl-color-primary-submarine);
    font-family: Source Code Pro;
    font-weight: 700;
  }
  .component1-text79 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
  .component1-text80 {
    color: var(--dl-color-primary-whiteish);
    font-family: Source Code Pro;
  }
}
