.home-container100 {
  width: 100%;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #292a2a;
}
.home-hero {
  width: 100%;
  height: 51px;
  display: flex;
  position: relative;
  align-items: center;
  padding-left: 0nits;
  flex-direction: column;
  padding-bottom: 0px;
  justify-content: center;
  background-color: #0e0e12;
}
.home-header {
  width: 100%;
  height: 44px;
  z-index: 900;
  max-width: 100%;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-sixunits);
  padding-right: var(--dl-space-space-sixunits);
  padding-bottom: var(--dl-space-space-unit);
}
.home-text100 {
  font-size: 20px;
  font-style: normal;
  font-family: "Raleway";
  font-weight: 600;
  margin-left: 150px;
  text-decoration: none;
}
.home-text101 {
  color: var(--dl-color-primary-tea);
}
.home-text102 {
  color: var(--dl-color-primary-submarine);
}
.home-nav1 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-burger-menu {
  display: none;
}
.home-icon10 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  display: flex;
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.home-nav2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container101 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-close-mobile-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon12 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-icon-group {
  border: 2px dashed rgba(120, 120, 120, 0.4);
}
.home-mobile-photo {
  flex: 0 0 auto;
  width: 481px;
  height: 658px;
  display: none;
  align-self: center;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-image100 {
  width: 467px;
  height: 594px;
  align-self: center;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 0px;
  border-top-left-radius: var(--dl-radius-radius-radius8);
  border-top-right-radius: var(--dl-radius-radius-radius8);
  border-bottom-left-radius: var(--dl-radius-radius-radius8);
  border-bottom-right-radius: var(--dl-radius-radius-radius8);
}
.home-discography-og {
  gap: var(--dl-space-space-fourunits);
  width: 1566px;
  height: 502px;
  display: none;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-slider1 {
  gap: var(--dl-space-space-fiveunits);
  width: 1654px;
  height: 449px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-slider-container1 {
  gap: initial;
  width: 1920px;
  height: 421px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-slider-track-container1 {
  width: 100%;
  align-items: center;
  justify-content: center;
}
.home-slider-track1 {
  gap: var(--dl-space-space-unit);
  width: 1304px;
  display: flex;
  opacity: 1;
}
.home-slide10 {
  position: relative;
  background-color: var(--dl-color-primary-mygreen);
}
.home-image101 {
  width: 300px;
  height: 300px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-slide11 {
  position: relative;
  background-color: var(--dl-color-primary-mygreen);
}
.home-text103 {
  top: 0px;
  left: 0px;
  color: rgba(253, 253, 253, 0);
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  position: absolute;
  font-size: 20px;
  text-align: center;
  transition: 0.3s;
  line-height: 1.5;
}
.home-text103:hover {
  color: var(--dl-color-primary-tea);
  opacity: 1;
  background-image: linear-gradient(0deg, rgba(48, 48, 48, 0.61) 0.00%,rgb(28, 27, 27) 100.00%);
}
.home-text104 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text105 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text106 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text107 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text108 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text109 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text110 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text111 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text112 {
  font-style: normal;
  font-family: Source Code Pro;
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.home-image102 {
  width: 300px;
  height: 300px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-slide12 {
  position: relative;
  background-color: var(--dl-color-primary-mygreen);
}
.home-text115 {
  top: 0px;
  left: 0px;
  color: rgba(0, 0, 0, 0);
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  position: absolute;
  font-size: 20px;
  text-align: center;
  transition: 0.3s;
  line-height: 1.5;
}
.home-text115:hover {
  color: var(--dl-color-primary-tea);
  opacity: 1;
  background-image: linear-gradient(0deg, rgba(48, 48, 48, 0.61) 0.00%,rgb(28, 27, 27) 100.00%);
}
.home-text116 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text117 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text118 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text119 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text120 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text121 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text122 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text123 {
  font-style: normal;
  font-family: Source Code Pro;
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.home-image103 {
  width: 300px;
  height: 300px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-slide13 {
  position: relative;
  background-color: var(--dl-color-primary-cleanslate);
}
.home-image104 {
  width: 300px;
  height: 300px;
  object-fit: cover;
  transition: 0.3s;
  aspect-ratio: 1;
}
.home-image104:hover {
  opacity: 0.2;
}
.home-text126 {
  top: 0px;
  left: 0px;
  color: rgba(255, 254, 254, 0);
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  position: absolute;
  font-size: 20px;
  text-align: center;
  transition: 0.3s;
  line-height: 1.5;
}
.home-text126:hover {
  color: var(--dl-color-primary-tea);
  opacity: 1;
  background-image: linear-gradient(0deg, rgba(48, 48, 48, 0.61) 0.00%,rgb(28, 27, 27) 100.00%);
}
.home-text128 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text130 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text133 {
  font-style: normal;
  font-family: Source Code Pro;
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.home-slide14 {
  position: relative;
  background-color: var(--dl-color-primary-mygreen);
}
.home-text136 {
  top: 0px;
  left: 0px;
  color: rgba(0, 0, 0, 0);
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  position: absolute;
  font-size: 20px;
  text-align: center;
  transition: 0.3s;
  line-height: 1.5;
}
.home-text136:hover {
  color: var(--dl-color-primary-tea);
  opacity: 1;
  background-image: linear-gradient(0deg, rgba(48, 48, 48, 0.61) 0.00%,rgb(28, 27, 27) 100.00%);
}
.home-text137 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text138 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text139 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text140 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text141 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text142 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text143 {
  font-style: normal;
  font-family: Source Code Pro;
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.home-image105 {
  width: 300px;
  height: 300px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-slide15 {
  position: relative;
  background-color: var(--dl-color-primary-mygreen);
}
.home-text146 {
  top: 0px;
  left: 0px;
  color: rgba(0, 0, 0, 0);
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  position: absolute;
  font-size: 20px;
  text-align: center;
  transition: 0.3s;
  line-height: 1.5;
}
.home-text146:hover {
  color: var(--dl-color-primary-tea);
  opacity: 1;
  background-image: linear-gradient(0deg, rgba(48, 48, 48, 0.61) 0.00%,rgb(28, 27, 27) 100.00%);
}
.home-text147 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text148 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text149 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text150 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text151 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text152 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text153 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text154 {
  font-style: normal;
  font-family: Source Code Pro;
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.home-image106 {
  width: 300px;
  height: 300px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-slide16 {
  position: relative;
  background-color: var(--dl-color-primary-mygreen);
}
.home-text157 {
  top: 0px;
  left: 0px;
  color: rgba(0, 0, 0, 0);
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  position: absolute;
  font-size: 20px;
  text-align: center;
  transition: 0.3s;
  line-height: 1.5;
}
.home-text157:hover {
  color: var(--dl-color-primary-tea);
  opacity: 1;
  background-image: linear-gradient(0deg, rgba(48, 48, 48, 0.61) 0.00%,rgb(28, 27, 27) 100.00%);
}
.home-text158 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text159 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text160 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text161 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text162 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text163 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text164 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text165 {
  font-style: normal;
  font-family: Source Code Pro;
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.home-image107 {
  width: 300px;
  height: 300px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-slide17 {
  position: relative;
  background-color: var(--dl-color-primary-cleanslate);
}
.home-image108 {
  width: 300px;
  height: 300px;
  object-fit: cover;
  transition: 0.3s;
  aspect-ratio: 1;
}
.home-image108:hover {
  opacity: 0.2;
}
.home-text168 {
  top: 0px;
  left: 0px;
  color: rgba(0, 0, 0, 0);
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  position: absolute;
  font-size: 20px;
  text-align: center;
  transition: 0.3s;
  line-height: 1.5;
}
.home-text168:hover {
  color: var(--dl-color-primary-tea);
  opacity: 1;
  background-image: linear-gradient(0deg, rgba(48, 48, 48, 0.61) 0.00%,rgb(28, 27, 27) 100.00%);
}
.home-text170 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text172 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text175 {
  font-style: normal;
  font-family: Source Code Pro;
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.home-slide18 {
  position: relative;
  background-color: var(--dl-color-primary-mygreen);
}
.home-text178 {
  top: 0px;
  left: 0px;
  color: rgba(0, 0, 0, 0);
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  position: absolute;
  font-size: 20px;
  text-align: center;
  transition: 0.3s;
  line-height: 1.5;
}
.home-text178:hover {
  color: var(--dl-color-primary-tea);
  opacity: 1;
  background-image: linear-gradient(0deg, rgba(48, 48, 48, 0.61) 0.00%,rgb(28, 27, 27) 100.00%);
}
.home-text179 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text180 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text181 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text182 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text183 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text184 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text185 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text186 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text187 {
  font-style: normal;
  font-family: Source Code Pro;
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.home-image109 {
  width: 300px;
  height: 300px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-slide19 {
  position: relative;
  background-color: var(--dl-color-primary-mygreen);
}
.home-text190 {
  top: 0px;
  left: 0px;
  color: rgba(197, 187, 172, 0);
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  position: absolute;
  font-size: 20px;
  text-align: center;
  transition: 0.3s;
  line-height: 1.5;
}
.home-text190:hover {
  color: var(--dl-color-primary-tea);
  opacity: 1;
  background-image: linear-gradient(0deg, rgba(48, 48, 48, 0.61) 0.00%,rgb(28, 27, 27) 100.00%);
}
.home-text191 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text192 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text193 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text194 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text195 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text196 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text197 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text198 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text199 {
  font-style: normal;
  font-family: Source Code Pro;
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.home-image110 {
  width: 300px;
  height: 300px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-slide20 {
  position: relative;
  background-color: var(--dl-color-primary-mygreen);
}
.home-text202 {
  top: 0px;
  left: 0px;
  color: rgba(0, 0, 0, 0);
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  position: absolute;
  font-size: 20px;
  text-align: center;
  transition: 0.3s;
  line-height: 1.5;
}
.home-text202:hover {
  color: var(--dl-color-primary-tea);
  opacity: 1;
  background-image: linear-gradient(0deg, rgba(48, 48, 48, 0.61) 0.00%,rgb(28, 27, 27) 100.00%);
}
.home-text203 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text204 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text205 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text206 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text207 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text208 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text209 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text210 {
  font-style: normal;
  font-family: Source Code Pro;
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.home-text211 {
  font-style: normal;
  font-family: Source Code Pro;
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.home-text212 {
  font-style: normal;
  font-family: Source Code Pro;
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.home-text213 {
  font-style: normal;
  font-family: Source Code Pro;
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.home-text214 {
  font-style: normal;
  font-family: Source Code Pro;
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.home-image111 {
  width: 300px;
  height: 300px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-slide21 {
  position: relative;
  background-color: var(--dl-color-primary-mygreen);
}
.home-text217 {
  top: 0px;
  left: 0px;
  color: rgba(234, 225, 213, 0);
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  position: absolute;
  font-size: 20px;
  text-align: center;
  transition: 0.3s;
  line-height: 1.5;
}
.home-text217:hover {
  color: var(--dl-color-primary-tea);
  opacity: 1;
  background-image: linear-gradient(0deg, rgba(48, 48, 48, 0.61) 0.00%,rgb(28, 27, 27) 100.00%);
}
.home-text218 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text219 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text220 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text221 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text222 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text223 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text224 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text225 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text226 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text227 {
  font-style: normal;
  font-family: Source Code Pro;
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.home-image112 {
  width: 300px;
  height: 300px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-slide22 {
  position: relative;
  background-color: var(--dl-color-primary-cleanslate);
}
.home-image113 {
  width: 300px;
  height: 300px;
  object-fit: cover;
  transition: 0.3s;
  aspect-ratio: 1;
}
.home-image113:hover {
  opacity: 0.2;
}
.home-text230 {
  top: 0px;
  left: 0px;
  color: rgba(0, 0, 0, 0);
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  position: absolute;
  font-size: 20px;
  text-align: center;
  transition: 0.3s;
  line-height: 1.5;
}
.home-text230:hover {
  color: var(--dl-color-primary-tea);
  opacity: 1;
  background-image: linear-gradient(0deg, rgba(48, 48, 48, 0.61) 0.00%,rgb(28, 27, 27) 100.00%);
}
.home-text236 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text239 {
  font-style: normal;
  font-family: Source Code Pro;
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.home-slide23 {
  position: relative;
}
.home-text242 {
  top: 0px;
  left: 0px;
  color: rgba(0, 0, 0, 0);
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  position: absolute;
  font-size: 20px;
  text-align: center;
  transition: 0.3s;
  line-height: 1.5;
}
.home-text242:hover {
  color: var(--dl-color-primary-tea);
  opacity: 1;
  background-image: linear-gradient(0deg, rgba(48, 48, 48, 0.61) 0.00%,rgb(28, 27, 27) 100.00%);
}
.home-text243 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text244 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text245 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text246 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text247 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text248 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text249 {
  font-style: normal;
  font-family: Source Code Pro;
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.home-image114 {
  width: 300px;
  height: 300px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-slide24 {
  position: relative;
  background-color: var(--dl-color-primary-mygreen);
}
.home-text252 {
  top: 0px;
  left: 0px;
  color: rgba(0, 0, 0, 0);
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  position: absolute;
  font-size: 20px;
  text-align: center;
  transition: 0.3s;
  line-height: 1.5;
}
.home-text252:hover {
  color: var(--dl-color-primary-tea);
  opacity: 1;
  background-image: linear-gradient(0deg, rgba(48, 48, 48, 0.61) 0.00%,rgb(28, 27, 27) 100.00%);
}
.home-text253 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text254 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text255 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text256 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text257 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text258 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text259 {
  font-style: normal;
  font-family: Source Code Pro;
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.home-image115 {
  width: 300px;
  height: 300px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-slide25 {
  position: relative;
  background-color: var(--dl-color-primary-cleanslate);
}
.home-image116 {
  width: 300px;
  height: 300px;
  object-fit: cover;
  transition: 0.3s;
  aspect-ratio: 1;
}
.home-image116:hover {
  opacity: 0.2;
}
.home-text262 {
  top: 0px;
  left: 0px;
  color: rgba(0, 0, 0, 0);
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  position: absolute;
  font-size: 20px;
  text-align: center;
  transition: 0.3s;
  line-height: 1.5;
}
.home-text262:hover {
  color: var(--dl-color-primary-tea);
  opacity: 1;
  background-image: linear-gradient(0deg, rgba(48, 48, 48, 0.61) 0.00%,rgb(28, 27, 27) 100.00%);
}
.home-text264 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text266 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text269 {
  font-style: normal;
  font-family: Source Code Pro;
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.home-slide26 {
  position: relative;
}
.home-text272 {
  top: 0px;
  left: 0px;
  color: rgba(0, 0, 0, 0);
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  position: absolute;
  font-size: 20px;
  text-align: center;
  transition: 0.3s;
  line-height: 1.5;
}
.home-text272:hover {
  color: var(--dl-color-primary-tea);
  opacity: 1;
  background-image: linear-gradient(0deg, rgba(48, 48, 48, 0.61) 0.00%,rgb(28, 27, 27) 100.00%);
}
.home-text273 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text274 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text275 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text276 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text277 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text278 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text279 {
  font-style: normal;
  font-family: Source Code Pro;
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.home-image117 {
  width: 300px;
  height: 300px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-slide27 {
  position: relative;
  background-color: var(--dl-color-primary-mygreen);
}
.home-text282 {
  top: 0px;
  left: 0px;
  color: rgba(0, 0, 0, 0);
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  position: absolute;
  font-size: 20px;
  text-align: center;
  transition: 0.3s;
  line-height: 1.5;
}
.home-text282:hover {
  color: var(--dl-color-primary-tea);
  opacity: 1;
  background-image: linear-gradient(0deg, rgba(48, 48, 48, 0.61) 0.00%,rgb(28, 27, 27) 100.00%);
}
.home-text283 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text284 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text285 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text286 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text287 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text288 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text289 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text290 {
  font-style: normal;
  font-family: Source Code Pro;
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.home-image118 {
  width: 300px;
  height: 300px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-slide28 {
  position: relative;
  background-color: var(--dl-color-primary-cleanslate);
}
.home-image119 {
  width: 300px;
  height: 300px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-text293 {
  top: 0px;
  left: 0px;
  color: rgba(0, 0, 0, 0);
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  position: absolute;
  font-size: 20px;
  text-align: center;
  transition: 0.3s;
  line-height: 1.5;
}
.home-text293:hover {
  color: var(--dl-color-primary-tea);
  opacity: 1;
  background-image: linear-gradient(0deg, rgba(48, 48, 48, 0.61) 0.00%,rgb(28, 27, 27) 100.00%);
}
.home-text295 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text297 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text300 {
  font-style: normal;
  font-family: Source Code Pro;
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.home-controls1 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-previous1 {
  display: flex;
  transition: 0.3s;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  background-color: var(--dl-color-primary-submarine);
}
.home-previous1:hover {
  background-color: var(--dl-color-primary-mygreen);
}
.home-icon14 {
  fill: var(--dl-color-primary-whiteish);
  width: 24px;
  height: 24px;
}
.home-next1 {
  display: flex;
  transition: 0.3s;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  background-color: var(--dl-color-primary-submarine);
}
.home-next1:hover {
  background-color: var(--dl-color-primary-mygreen);
}
.home-icon16 {
  fill: var(--dl-color-primary-whiteish);
  width: 24px;
  height: 24px;
}
.home-container103 {
  display: contents;
}
.home-discography-mobile {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: none;
  overflow: hidden;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-primary-myblack);
}
.home-container104 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 50vw 50vw;
}
.home-container105 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image120 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-text303 {
  color: var(--dl-color-primary-whiteish);
}
.home-text306 {
  color: var(--dl-color-primary-whiteish);
  font-style: italic;
}
.home-text307 {
  color: var(--dl-color-primary-whiteish);
}
.home-container107 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image121 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-text310 {
  color: var(--dl-color-primary-whiteish);
}
.home-text311 {
  color: var(--dl-color-primary-whiteish);
  font-style: italic;
}
.home-text312 {
  color: var(--dl-color-primary-whiteish);
}
.home-container109 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image122 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-container110 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.home-text315 {
  color: var(--dl-color-primary-whiteish);
}
.home-text316 {
  color: var(--dl-color-primary-whiteish);
  font-style: italic;
}
.home-text317 {
  color: var(--dl-color-primary-whiteish);
}
.home-container111 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image123 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-text320 {
  color: rgb(249, 242, 242);
}
.home-text321 {
  color: rgb(249, 242, 242);
  font-size: 16px;
  font-style: italic;
  font-family: "Inter";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.home-text322 {
  color: rgb(249, 242, 242);
}
.home-container113 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image124 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-text325 {
  color: var(--dl-color-primary-whiteish);
}
.home-text326 {
  color: var(--dl-color-primary-whiteish);
  font-style: italic;
}
.home-text327 {
  color: var(--dl-color-primary-whiteish);
}
.home-container115 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image125 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-container116 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.home-text330 {
  color: rgb(249, 242, 242);
}
.home-text331 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text332 {
  color: rgb(249, 242, 242);
}
.home-container117 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image126 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-container118 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.home-text335 {
  color: rgb(249, 242, 242);
}
.home-text336 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text337 {
  color: rgb(249, 242, 242);
}
.home-container119 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image127 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-container120 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.home-text340 {
  color: rgb(249, 242, 242);
}
.home-text341 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text342 {
  color: rgb(249, 242, 242);
}
.home-container121 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image128 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-container122 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.home-text345 {
  color: rgb(249, 242, 242);
}
.home-text346 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text347 {
  color: rgb(249, 242, 242);
}
.home-container123 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image129 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-container124 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.home-text350 {
  color: rgb(249, 242, 242);
}
.home-text351 {
  color: #f9f2f2;
  font-size: 16px;
  font-style: italic;
  font-family: "Inter";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.home-text352 {
  color: rgb(249, 242, 242);
}
.home-container125 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image130 {
  object-fit: cover;
  aspect-ratio: 1;
  margin-bottom: 5px;
}
.home-text355 {
  color: rgb(249, 242, 242);
}
.home-text356 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text357 {
  color: rgb(249, 242, 242);
}
.home-container127 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image131 {
  object-fit: cover;
  aspect-ratio: 1;
  margin-bottom: 5px;
}
.home-text360 {
  color: rgb(249, 242, 242);
  font-weight: 700;
}
.home-text361 {
  color: rgb(249, 242, 242);
}
.home-text362 {
  color: rgb(249, 242, 242);
}
.home-container129 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image132 {
  object-fit: cover;
  aspect-ratio: 1;
  margin-bottom: 5px;
}
.home-text365 {
  color: rgb(249, 242, 242);
}
.home-text366 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text367 {
  color: rgb(249, 242, 242);
}
.home-container131 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image133 {
  object-fit: cover;
  aspect-ratio: 1;
  margin-bottom: 5px;
}
.home-text370 {
  color: rgb(249, 242, 242);
}
.home-text373 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text374 {
  color: rgb(249, 242, 242);
}
.home-container133 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image134 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-container134 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.home-text377 {
  color: rgb(249, 242, 242);
}
.home-text380 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text381 {
  color: rgb(249, 242, 242);
}
.home-container135 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image135 {
  object-fit: cover;
  aspect-ratio: 1;
  margin-bottom: 5px;
}
.home-text384 {
  color: rgb(249, 242, 242);
}
.home-text387 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text388 {
  color: rgb(249, 242, 242);
}
.home-container137 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image136 {
  object-fit: cover;
  aspect-ratio: 1;
  margin-bottom: 5px;
}
.home-text391 {
  color: rgb(249, 242, 242);
}
.home-text394 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text395 {
  color: rgb(249, 242, 242);
}
.home-container139 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image137 {
  object-fit: cover;
  aspect-ratio: 1;
  margin-bottom: 5px;
}
.home-text399 {
  color: rgb(249, 242, 242);
}
.home-text402 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text403 {
  color: rgb(249, 242, 242);
}
.home-container141 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image138 {
  object-fit: cover;
  aspect-ratio: 1;
  margin-bottom: 5px;
}
.home-text406 {
  color: rgb(249, 242, 242);
}
.home-text409 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text410 {
  color: rgb(249, 242, 242);
}
.home-container143 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image139 {
  object-fit: cover;
  aspect-ratio: 1;
  margin-bottom: 5px;
}
.home-text413 {
  color: rgb(249, 242, 242);
}
.home-text416 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text417 {
  color: rgb(249, 242, 242);
}
.home-container145 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image140 {
  object-fit: cover;
  aspect-ratio: 1;
  margin-bottom: 5px;
}
.home-text420 {
  color: rgb(249, 242, 242);
}
.home-text423 {
  color: rgb(249, 242, 242);
}
.home-text424 {
  color: rgb(249, 242, 242);
}
.home-container147 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image141 {
  object-fit: cover;
  aspect-ratio: 1;
  margin-bottom: 5px;
}
.home-text427 {
  color: rgb(249, 242, 242);
}
.home-text430 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text431 {
  color: rgb(249, 242, 242);
}
.home-container149 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image142 {
  object-fit: cover;
  aspect-ratio: 1;
  margin-bottom: 5px;
}
.home-text434 {
  color: rgb(249, 242, 242);
}
.home-text437 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text438 {
  color: rgb(249, 242, 242);
}
.home-container151 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image143 {
  object-fit: cover;
  aspect-ratio: 1;
  margin-bottom: 5px;
}
.home-text441 {
  color: rgb(249, 242, 242);
}
.home-text444 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text445 {
  color: rgb(249, 242, 242);
}
.home-container153 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image144 {
  object-fit: cover;
  aspect-ratio: 1;
  margin-bottom: 5px;
}
.home-text448 {
  color: rgb(249, 242, 242);
}
.home-text451 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text452 {
  color: rgb(249, 242, 242);
}
.home-container155 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image145 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-container156 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.home-text455 {
  color: rgb(249, 242, 242);
}
.home-text456 {
  color: #f9f2f2;
  font-size: 16px;
  font-style: italic;
  font-family: "Inter";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.home-text457 {
  color: rgb(249, 242, 242);
}
.home-container157 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image146 {
  object-fit: cover;
  aspect-ratio: 1;
  margin-bottom: 5px;
}
.home-text460 {
  color: rgb(249, 242, 242);
}
.home-text463 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text464 {
  color: rgb(249, 242, 242);
}
.home-container159 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image147 {
  object-fit: cover;
  aspect-ratio: 1;
  margin-bottom: 5px;
}
.home-text467 {
  color: rgb(249, 242, 242);
}
.home-text470 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text471 {
  color: rgb(249, 242, 242);
}
.home-discography-desktop {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  height: 6690px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-primary-myblack);
}
.home-container161 {
  width: 100%;
  height: 3470px;
  display: grid;
  grid-template-rows: 33vw 33vw 33vw 33vw;
  grid-template-columns: 25vw 25vw 25vw 25vw;
}
.home-container162 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image148 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-text474 {
  color: var(--dl-color-primary-whiteish);
}
.home-text477 {
  color: var(--dl-color-primary-whiteish);
  font-style: italic;
}
.home-text478 {
  color: var(--dl-color-primary-whiteish);
}
.home-container164 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image149 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-text481 {
  color: var(--dl-color-primary-whiteish);
}
.home-text482 {
  color: var(--dl-color-primary-whiteish);
  font-style: italic;
}
.home-text483 {
  color: var(--dl-color-primary-whiteish);
}
.home-container166 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image150 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-text486 {
  color: var(--dl-color-primary-whiteish);
}
.home-text489 {
  color: var(--dl-color-primary-whiteish);
  font-style: italic;
}
.home-text490 {
  color: var(--dl-color-primary-whiteish);
}
.home-container168 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image151 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-text493 {
  color: var(--dl-color-primary-whiteish);
}
.home-text494 {
  color: var(--dl-color-primary-whiteish);
  font-style: italic;
}
.home-text495 {
  color: var(--dl-color-primary-whiteish);
}
.home-container170 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image152 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-container171 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.home-text498 {
  color: rgb(249, 242, 242);
}
.home-text499 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text500 {
  color: rgb(249, 242, 242);
}
.home-container172 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image153 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-container173 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.home-text503 {
  color: rgb(249, 242, 242);
}
.home-text504 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text505 {
  color: rgb(249, 242, 242);
}
.home-container174 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image154 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-container175 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.home-text508 {
  color: rgb(249, 242, 242);
}
.home-text509 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text510 {
  color: rgb(249, 242, 242);
}
.home-container176 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image155 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-text513 {
  color: var(--dl-color-primary-whiteish);
}
.home-text514 {
  color: var(--dl-color-primary-whiteish);
  font-style: italic;
}
.home-text515 {
  color: var(--dl-color-primary-whiteish);
}
.home-container178 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image156 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-text518 {
  color: rgb(249, 242, 242);
}
.home-text519 {
  color: rgb(249, 242, 242);
  font-size: 16px;
  font-style: italic;
  font-family: "Inter";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.home-text520 {
  color: rgb(249, 242, 242);
}
.home-container180 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image157 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-text523 {
  color: rgb(249, 242, 242);
}
.home-text524 {
  color: rgb(249, 242, 242);
  font-size: 16px;
  font-style: italic;
  font-family: "Inter";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.home-text525 {
  color: rgb(249, 242, 242);
}
.home-container182 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image158 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-container183 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.home-text528 {
  color: var(--dl-color-primary-whiteish);
}
.home-text529 {
  color: var(--dl-color-primary-whiteish);
  font-style: italic;
}
.home-text530 {
  color: var(--dl-color-primary-whiteish);
}
.home-container184 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image159 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-text533 {
  color: var(--dl-color-primary-whiteish);
}
.home-text534 {
  color: var(--dl-color-primary-whiteish);
  font-style: italic;
}
.home-text535 {
  color: var(--dl-color-primary-whiteish);
}
.home-container186 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image160 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-container187 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.home-text538 {
  color: rgb(249, 242, 242);
}
.home-text539 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text540 {
  color: rgb(249, 242, 242);
}
.home-container188 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image161 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-container189 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.home-text543 {
  color: rgb(249, 242, 242);
}
.home-text544 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text545 {
  color: rgb(249, 242, 242);
}
.home-container190 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image162 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-container191 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.home-text548 {
  color: rgb(249, 242, 242);
}
.home-text549 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text550 {
  color: rgb(249, 242, 242);
}
.home-container192 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image163 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-container193 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.home-text553 {
  color: rgb(249, 242, 242);
}
.home-text554 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text555 {
  color: rgb(249, 242, 242);
}
.home-container194 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image164 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-container195 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.home-text558 {
  color: rgb(249, 242, 242);
}
.home-text559 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text560 {
  color: rgb(249, 242, 242);
}
.home-container196 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image165 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-container197 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.home-text563 {
  color: rgb(249, 242, 242);
}
.home-text564 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text565 {
  color: rgb(249, 242, 242);
}
.home-container198 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image166 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-container199 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.home-text568 {
  color: rgb(249, 242, 242);
}
.home-text569 {
  font-weight: 700;
}
.home-text571 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text572 {
  color: rgb(249, 242, 242);
}
.home-container200 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image167 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-container201 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.home-text575 {
  color: rgb(249, 242, 242);
}
.home-text576 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text577 {
  color: rgb(249, 242, 242);
}
.home-container202 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image168 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-container203 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.home-text580 {
  color: rgb(249, 242, 242);
}
.home-text581 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text582 {
  color: rgb(249, 242, 242);
}
.home-container204 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image169 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-container205 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.home-text585 {
  color: rgb(249, 242, 242);
}
.home-text586 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text587 {
  color: rgb(249, 242, 242);
}
.home-container206 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image170 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-container207 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.home-text590 {
  color: rgb(249, 242, 242);
}
.home-text591 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text592 {
  color: rgb(249, 242, 242);
}
.home-container208 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image171 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-container209 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.home-text595 {
  color: rgb(249, 242, 242);
}
.home-text596 {
  color: #f9f2f2;
  font-size: 16px;
  font-style: italic;
  font-family: "Inter";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.home-text597 {
  color: rgb(249, 242, 242);
}
.home-container210 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image172 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-container211 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.home-text600 {
  color: rgb(249, 242, 242);
}
.home-text601 {
  color: #f9f2f2;
  font-size: 16px;
  font-style: italic;
  font-family: "Inter";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.home-text602 {
  color: rgb(249, 242, 242);
}
.home-container212 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image173 {
  object-fit: cover;
  aspect-ratio: 1;
  margin-bottom: 5px;
}
.home-text605 {
  color: rgb(249, 242, 242);
}
.home-text606 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text607 {
  color: rgb(249, 242, 242);
}
.home-container214 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image174 {
  object-fit: cover;
  aspect-ratio: 1;
  margin-bottom: 5px;
}
.home-text610 {
  color: rgb(249, 242, 242);
}
.home-text611 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text612 {
  color: rgb(249, 242, 242);
}
.home-container216 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image175 {
  object-fit: cover;
  aspect-ratio: 1;
  margin-bottom: 5px;
}
.home-text615 {
  color: rgb(249, 242, 242);
}
.home-text618 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text619 {
  color: rgb(249, 242, 242);
}
.home-container218 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image176 {
  object-fit: cover;
  aspect-ratio: 1;
  margin-bottom: 5px;
}
.home-text622 {
  color: rgb(249, 242, 242);
  font-weight: 700;
}
.home-text623 {
  color: rgb(249, 242, 242);
}
.home-text624 {
  color: rgb(249, 242, 242);
}
.home-container220 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image177 {
  object-fit: cover;
  aspect-ratio: 1;
  margin-bottom: 5px;
}
.home-text627 {
  color: rgb(249, 242, 242);
  font-weight: 700;
}
.home-text628 {
  color: rgb(249, 242, 242);
}
.home-text629 {
  color: rgb(249, 242, 242);
}
.home-container222 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image178 {
  object-fit: cover;
  aspect-ratio: 1;
  margin-bottom: 5px;
}
.home-text632 {
  color: rgb(249, 242, 242);
}
.home-text635 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text636 {
  color: rgb(249, 242, 242);
}
.home-container224 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image179 {
  object-fit: cover;
  aspect-ratio: 1;
  margin-bottom: 5px;
}
.home-text639 {
  color: rgb(249, 242, 242);
}
.home-text642 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text643 {
  color: rgb(249, 242, 242);
}
.home-container226 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image180 {
  object-fit: cover;
  aspect-ratio: 1;
  margin-bottom: 5px;
}
.home-text647 {
  color: rgb(249, 242, 242);
}
.home-text650 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text651 {
  color: rgb(249, 242, 242);
}
.home-container228 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image181 {
  object-fit: cover;
  aspect-ratio: 1;
  margin-bottom: 5px;
}
.home-text654 {
  color: rgb(249, 242, 242);
}
.home-text657 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text658 {
  color: rgb(249, 242, 242);
}
.home-container230 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image182 {
  object-fit: cover;
  aspect-ratio: 1;
  margin-bottom: 5px;
}
.home-text661 {
  color: rgb(249, 242, 242);
}
.home-text664 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text665 {
  color: rgb(249, 242, 242);
}
.home-container232 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image183 {
  object-fit: cover;
  aspect-ratio: 1;
  margin-bottom: 5px;
}
.home-text668 {
  color: rgb(249, 242, 242);
}
.home-text671 {
  color: rgb(249, 242, 242);
}
.home-text672 {
  color: rgb(249, 242, 242);
}
.home-container234 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image184 {
  object-fit: cover;
  aspect-ratio: 1;
  margin-bottom: 5px;
}
.home-text675 {
  color: rgb(249, 242, 242);
}
.home-text678 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text679 {
  color: rgb(249, 242, 242);
}
.home-container236 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image185 {
  object-fit: cover;
  aspect-ratio: 1;
  margin-bottom: 5px;
}
.home-text682 {
  color: rgb(249, 242, 242);
}
.home-text685 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text686 {
  color: rgb(249, 242, 242);
}
.home-container238 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image186 {
  object-fit: cover;
  aspect-ratio: 1;
  margin-bottom: 5px;
}
.home-text689 {
  color: rgb(249, 242, 242);
}
.home-text692 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text693 {
  color: rgb(249, 242, 242);
}
.home-container240 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image187 {
  object-fit: cover;
  aspect-ratio: 1;
  margin-bottom: 5px;
}
.home-text696 {
  color: rgb(249, 242, 242);
}
.home-text699 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text700 {
  color: rgb(249, 242, 242);
}
.home-container242 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image188 {
  object-fit: cover;
  aspect-ratio: 1;
  margin-bottom: 5px;
}
.home-text703 {
  color: rgb(249, 242, 242);
}
.home-text706 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text707 {
  color: rgb(249, 242, 242);
}
.home-container244 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image189 {
  object-fit: cover;
  aspect-ratio: 1;
  margin-bottom: 5px;
}
.home-text710 {
  color: rgb(249, 242, 242);
}
.home-text713 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text714 {
  color: rgb(249, 242, 242);
}
.home-container246 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image190 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-container247 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.home-text717 {
  color: rgb(249, 242, 242);
}
.home-text718 {
  color: rgb(249, 242, 242);
}
.home-text719 {
  color: rgb(249, 242, 242);
}
.home-container248 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image191 {
  object-fit: cover;
  aspect-ratio: 1;
  margin-bottom: 5px;
}
.home-text722 {
  color: rgb(249, 242, 242);
}
.home-text725 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text726 {
  color: rgb(249, 242, 242);
}
.home-container250 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image192 {
  object-fit: cover;
  aspect-ratio: 1;
  padding-bottom: 5px;
}
.home-container251 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.home-text729 {
  color: rgb(249, 242, 242);
}
.home-text730 {
  color: #f9f2f2;
  font-size: 16px;
  font-style: italic;
  font-family: "Inter";
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.home-text731 {
  color: rgb(249, 242, 242);
}
.home-container252 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image193 {
  object-fit: cover;
  aspect-ratio: 1;
  margin-bottom: 5px;
}
.home-text734 {
  color: rgb(249, 242, 242);
}
.home-text737 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text738 {
  color: rgb(249, 242, 242);
}
.home-container254 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image194 {
  object-fit: cover;
  aspect-ratio: 1;
  margin-bottom: 5px;
}
.home-text741 {
  color: rgb(249, 242, 242);
}
.home-text744 {
  color: rgb(249, 242, 242);
  font-style: italic;
}
.home-text745 {
  color: rgb(249, 242, 242);
}
.home-spotify-player {
  width: 1050px;
  height: 930px;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-left: calc(8px + 1.5vw);
  padding-right: calc(8px + 1.5vw);
  flex-direction: column;
  justify-content: center;
}
.home-container256 {
  width: 100%;
  height: 147px;
  display: flex;
  align-self: center;
  align-items: flex-start;
}
.home-container257 {
  flex: 0 0 auto;
  width: 463px;
  height: 100px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  margin-left: 0px;
  justify-content: center;
}
.home-container258 {
  flex: 0 0 auto;
  width: 42px;
  height: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-icon18 {
  fill: var(--dl-color-primary-submarine);
  width: 100%;
  height: 100%;
}
.home-heading1 {
  color: rgb(234, 225, 213);
  width: 416px;
  font-size: 70px;
  max-width: 790px;
  align-self: center;
  font-style: normal;
  text-align: left;
  transition: 0.3s;
  font-family: Exo 2;
  font-weight: 500;
}
.home-heading1:hover {
  color: var(--dl-color-primary-tea);
}
.home-text748 {
  font-family: Raleway;
}
.home-container259 {
  width: 845px;
  height: 400px;
  align-self: center;
  max-height: 400px;
  margin-bottom: 30px;
}
.home-container260 {
  display: contents;
}
.home-live-sessions {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  height: 323px;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
}
.home-container261 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  justify-content: center;
}
.home-container262 {
  width: 434px;
  height: 278px;
  align-self: flex-start;
}
.home-container263 {
  display: contents;
}
.home-container264 {
  width: 427px;
  height: 100%;
  align-self: flex-start;
  padding-left: 0px;
}
.home-container265 {
  display: contents;
}
.home-container266 {
  width: 100%;
  height: 21px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.home-text750 {
  top: -23px;
  left: 183px;
  color: var(--dl-color-gray-500);
  position: absolute;
  text-align: center;
  font-family: Raleway;
}
.home-about {
  width: 1640px;
  height: 1006px;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-end;
  padding-top: 120px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: flex-start;
}
.home-container267 {
  width: 1161px;
  height: 782px;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-self: center;
  align-items: flex-start;
  border-color: #2c2d2d;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  justify-content: center;
}
.home-container268 {
  flex: 0 0 auto;
  width: 50%;
  height: 694px;
  display: flex;
  flex-wrap: wrap;
  align-self: flex-start;
  align-items: center;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: center;
}
.home-container269 {
  flex: 0 0 auto;
  width: auto;
  height: 100px;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  justify-content: center;
}
.home-container270 {
  flex: 0 0 auto;
  width: 42px;
  height: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-icon20 {
  fill: var(--dl-color-primary-submarine);
  width: 100%;
  height: 100%;
}
.home-heading2 {
  color: rgb(234, 225, 213);
  width: 416px;
  font-size: 70px;
  max-width: 790px;
  align-self: center;
  font-style: normal;
  text-align: left;
  transition: 0.3s;
  font-family: Exo 2;
  font-weight: 500;
}
.home-heading2:hover {
  color: var(--dl-color-primary-tea);
}
.home-text751 {
  font-family: Raleway;
}
.home-caption {
  color: var(--dl-color-primary-whiteish);
  width: 488px;
  height: 459px;
  font-size: 16px;
  max-width: 600px;
  align-self: center;
  margin-top: var(--dl-space-space-oneandhalfunits);
  text-align: justify;
  line-height: 21px;
}
.home-text753 {
  font-weight: 700;
}
.home-text756 {
  font-weight: 700;
}
.home-text762 {
  font-weight: 700;
}
.home-text764 {
  font-weight: 700;
}
.home-text766 {
  font-weight: 700;
}
.home-text768 {
  font-weight: 700;
}
.home-text770 {
  font-weight: 700;
}
.home-text772 {
  font-weight: 700;
}
.home-text774 {
  font-weight: 700;
}
.home-text775 {
  font-weight: 700;
}
.home-text776 {
  font-weight: 700;
}
.home-container271 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-fourunits);
  justify-content: flex-end;
}
.home-link1 {
  display: contents;
}
.home-icon22 {
  fill: var(--dl-color-primary-submarine);
  color: var(--dl-color-primary-tea);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  transition: 0.3s;
  margin-right: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.home-icon22:hover {
  fill: var(--dl-color-primary-mygreen);
}
.home-link2 {
  display: contents;
}
.home-icon24 {
  fill: var(--dl-color-primary-submarine);
  color: var(--dl-color-primary-tea);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  transition: 0.3s;
  text-decoration: none;
}
.home-icon24:hover {
  fill: var(--dl-color-primary-mygreen);
}
.home-container272 {
  flex: 0 0 auto;
  width: 441px;
  height: 658px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-end;
}
.home-container273 {
  width: 429px;
  height: 550px;
  display: flex;
  position: relative;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: 0px;
  background-size: cover;
  justify-content: center;
  background-image: url('https://freight.cargo.site/w/1000/q/75/i/7b4657985ec23c398deb3e89e5b0824834f44d4c9fe305046822e083954f573d/linhnguyen_ruby_linhnguyen_010.jpg');
  background-position: center;
  border-top-left-radius: var(--dl-radius-radius-radius8);
  border-top-right-radius: var(--dl-radius-radius-radius8);
  border-bottom-left-radius: var(--dl-radius-radius-radius8);
  border-bottom-right-radius: var(--dl-radius-radius-radius8);
}
.home-text783 {
  left: 0px;
  color: rgba(0, 0, 0, 0);
  right: 0px;
  width: 100%;
  bottom: -2px;
  height: 100%;
  margin: auto;
  position: absolute;
  align-self: flex-end;
  text-align: center;
  transition: 0.3s;
  font-family: Raleway;
  background-image: linear-gradient(rgba(189, 195, 199, 0) 0%, rgba(37, 38, 38, 0) 100%);
}
.home-text783:hover {
  color: var(--dl-color-primary-tea);
  background-image: linear-gradient(180deg, rgba(189, 195, 199, 0) 0.00%,rgba(37, 38, 38, 0.86) 99.00%);
}
.home-text784:hover {
  background-image: linear-gradient(180deg, rgba(189, 195, 199, 0) 0.00%,rgb(37, 38, 38) 100.00%);
}
.home-text785:hover {
  background-image: linear-gradient(180deg, rgba(189, 195, 199, 0) 0.00%,rgb(37, 38, 38) 100.00%);
}
.home-text786:hover {
  background-image: linear-gradient(180deg, rgba(189, 195, 199, 0) 0.00%,rgb(37, 38, 38) 100.00%);
}
.home-text787:hover {
  background-image: linear-gradient(180deg, rgba(189, 195, 199, 0) 0.00%,rgb(37, 38, 38) 100.00%);
}
.home-text788:hover {
  background-image: linear-gradient(180deg, rgba(189, 195, 199, 0) 0.00%,rgb(37, 38, 38) 100.00%);
}
.home-text789:hover {
  background-image: linear-gradient(180deg, rgba(189, 195, 199, 0) 0.00%,rgb(37, 38, 38) 100.00%);
}
.home-text790:hover {
  background-image: linear-gradient(180deg, rgba(189, 195, 199, 0) 0.00%,rgb(37, 38, 38) 100.00%);
}
.home-text791:hover {
  background-image: linear-gradient(180deg, rgba(189, 195, 199, 0) 0.00%,rgb(37, 38, 38) 100.00%);
}
.home-text792:hover {
  background-image: linear-gradient(180deg, rgba(189, 195, 199, 0) 0.00%,rgb(37, 38, 38) 100.00%);
}
.home-text793:hover {
  background-image: linear-gradient(180deg, rgba(189, 195, 199, 0) 0.00%,rgb(37, 38, 38) 100.00%);
}
.home-text794:hover {
  background-image: linear-gradient(180deg, rgba(189, 195, 199, 0) 0.00%,rgb(37, 38, 38) 100.00%);
}
.home-text795:hover {
  background-image: linear-gradient(180deg, rgba(189, 195, 199, 0) 0.00%,rgb(37, 38, 38) 100.00%);
}
.home-text796:hover {
  background-image: linear-gradient(180deg, rgba(189, 195, 199, 0) 0.00%,rgb(37, 38, 38) 100.00%);
}
.home-text797:hover {
  background-image: linear-gradient(180deg, rgba(189, 195, 199, 0) 0.00%,rgb(37, 38, 38) 100.00%);
}
.home-text798:hover {
  background-image: linear-gradient(180deg, rgba(189, 195, 199, 0) 0.00%,rgb(37, 38, 38) 100.00%);
}
.home-text799:hover {
  background-image: linear-gradient(180deg, rgba(189, 195, 199, 0) 0.00%,rgb(37, 38, 38) 100.00%);
}
.home-text800:hover {
  background-image: linear-gradient(180deg, rgba(189, 195, 199, 0) 0.00%,rgb(37, 38, 38) 100.00%);
}
.home-text801:hover {
  background-image: linear-gradient(180deg, rgba(189, 195, 199, 0) 0.00%,rgb(37, 38, 38) 100.00%);
}
.home-text802:hover {
  background-image: linear-gradient(180deg, rgba(189, 195, 199, 0) 0.00%,rgb(37, 38, 38) 100.00%);
}
.home-text803:hover {
  background-image: linear-gradient(180deg, rgba(189, 195, 199, 0) 0.00%,rgb(37, 38, 38) 100.00%);
}
.home-text804:hover {
  background-image: linear-gradient(180deg, rgba(189, 195, 199, 0) 0.00%,rgb(37, 38, 38) 100.00%);
}
.home-text805:hover {
  background-image: linear-gradient(180deg, rgba(189, 195, 199, 0) 0.00%,rgb(37, 38, 38) 100.00%);
}
.home-text806:hover {
  background-image: linear-gradient(180deg, rgba(189, 195, 199, 0) 0.00%,rgb(37, 38, 38) 100.00%);
}
.home-text807:hover {
  background-image: linear-gradient(180deg, rgba(189, 195, 199, 0) 0.00%,rgb(37, 38, 38) 100.00%);
}
.home-text808:hover {
  background-image: linear-gradient(180deg, rgba(189, 195, 199, 0) 0.00%,rgb(37, 38, 38) 100.00%);
}
.home-text809:hover {
  background-image: linear-gradient(180deg, rgba(189, 195, 199, 0) 0.00%,rgb(37, 38, 38) 100.00%);
}
.home-text810:hover {
  background-image: linear-gradient(180deg, rgba(189, 195, 199, 0) 0.00%,rgb(37, 38, 38) 100.00%);
}
.home-text811:hover {
  background-image: linear-gradient(180deg, rgba(189, 195, 199, 0) 0.00%,rgb(37, 38, 38) 100.00%);
}
.home-text812:hover {
  background-image: linear-gradient(180deg, rgba(189, 195, 199, 0) 0.00%,rgb(37, 38, 38) 100.00%);
}
.home-text813 {
  font-weight: 700;
}
.home-text813:hover {
  background-image: linear-gradient(180deg, rgba(189, 195, 199, 0) 0.00%,rgb(37, 38, 38) 100.00%);
}
.home-discography {
  gap: var(--dl-space-space-fourunits);
  height: 465px;
  display: none;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-slider2 {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-slider-container2 {
  gap: initial;
  width: 1224px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-slider-track-container2 {
  width: 100%;
  align-items: center;
  justify-content: center;
}
.home-slider-track2 {
  gap: var(--dl-space-space-unit);
  width: 1304px;
  display: flex;
  opacity: 1;
}
.home-slide29 {
  position: relative;
  background-color: var(--dl-color-primary-mygreen);
}
.home-text815 {
  top: 0px;
  left: 0px;
  color: rgba(197, 187, 172, 0);
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  position: absolute;
  font-size: x-large;
  text-align: center;
  transition: 0.3s;
  line-height: 1.5;
}
.home-text815:hover {
  color: var(--dl-color-primary-tea);
  opacity: 1;
  background-image: linear-gradient(0deg, rgba(48, 48, 48, 0.61) 0.00%,rgb(28, 27, 27) 100.00%);
}
.home-text816 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text817 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text818 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text819 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text820 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text821 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text822 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text823 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text824 {
  font-style: normal;
  font-family: Source Code Pro;
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.home-image195 {
  width: 300px;
  height: 300px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-slide30 {
  position: relative;
  background-color: var(--dl-color-primary-cleanslate);
}
.home-image196 {
  width: 300px;
  height: 300px;
  object-fit: cover;
  transition: 0.3s;
  aspect-ratio: 1;
}
.home-image196:hover {
  opacity: 0.2;
}
.home-text827 {
  top: 0px;
  left: 0px;
  color: rgba(0, 0, 0, 0);
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  position: absolute;
  font-size: x-large;
  text-align: center;
  transition: 0.3s;
  line-height: 1.5;
}
.home-text827:hover {
  color: var(--dl-color-primary-tea);
  opacity: 1;
  background-image: linear-gradient(0deg, rgba(48, 48, 48, 0.61) 0.00%,rgb(28, 27, 27) 100.00%);
}
.home-text829 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text831 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text834 {
  font-style: normal;
  font-family: Source Code Pro;
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.home-slide31 {
  position: relative;
}
.home-text837 {
  top: 0px;
  left: 0px;
  color: rgba(0, 0, 0, 0);
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  position: absolute;
  font-size: x-large;
  text-align: center;
  transition: 0.3s;
  line-height: 1.5;
}
.home-text837:hover {
  color: var(--dl-color-primary-tea);
  opacity: 1;
  background-image: linear-gradient(0deg, rgba(48, 48, 48, 0.61) 0.00%,rgb(28, 27, 27) 100.00%);
}
.home-text838 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text839 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text840 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text841 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text842 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text843 {
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text844 {
  font-style: normal;
  font-family: Source Code Pro;
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.home-image197 {
  width: 300px;
  height: 300px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-slide32 {
  position: relative;
}
.home-text847 {
  top: 0px;
  left: 0px;
  color: rgba(197, 187, 172, 0);
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  position: absolute;
  font-size: x-large;
  text-align: center;
  transition: 0.3s;
  line-height: 1.5;
}
.home-text847:hover {
  color: var(--dl-color-primary-tea);
  opacity: 1;
  background-image: linear-gradient(0deg, rgba(48, 48, 48, 0.61) 0.00%,rgb(28, 27, 27) 100.00%);
}
.home-image198 {
  width: 300px;
  height: 300px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-slide33 {
  position: relative;
  background-color: var(--dl-color-primary-cleanslate);
}
.home-image199 {
  width: 300px;
  height: 300px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-text848 {
  top: 0px;
  left: 0px;
  color: rgba(0, 0, 0, 0);
  width: 100%;
  bottom: 0px;
  height: 100%;
  margin: auto;
  position: absolute;
  font-size: x-large;
  text-align: center;
  transition: 0.3s;
  line-height: 1.5;
}
.home-text848:hover {
  color: var(--dl-color-primary-tea);
  opacity: 1;
  background-image: linear-gradient(0deg, rgba(48, 48, 48, 0.61) 0.00%,rgb(28, 27, 27) 100.00%);
}
.home-text849 {
  font-size: 25px;
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text850 {
  font-size: 25px;
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text851 {
  font-size: 25px;
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text852 {
  font-size: 25px;
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text853 {
  font-size: 25px;
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text854 {
  font-size: 25px;
  font-family: Raleway;
  text-transform: none;
  text-decoration: none;
}
.home-text855 {
  font-size: 23px;
  font-style: normal;
  font-family: Source Code Pro;
  font-weight: 300;
  text-transform: none;
  text-decoration: none;
}
.home-controls2 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-previous2 {
  display: flex;
  transition: 0.3s;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  background-color: var(--dl-color-primary-submarine);
}
.home-previous2:hover {
  background-color: var(--dl-color-primary-mygreen);
}
.home-icon26 {
  fill: var(--dl-color-primary-whiteish);
  width: 24px;
  height: 24px;
}
.home-next2 {
  display: flex;
  transition: 0.3s;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  background-color: var(--dl-color-primary-submarine);
}
.home-next2:hover {
  background-color: var(--dl-color-primary-mygreen);
}
.home-icon28 {
  fill: var(--dl-color-primary-whiteish);
  width: 24px;
  height: 24px;
}
.home-container275 {
  display: contents;
}
.home-what-i-do-web {
  width: 1050px;
  height: 932px;
  display: flex;
  align-items: flex-start;
  padding-left: calc(8px + 1.5vw);
  padding-right: calc(8px + 1.5vw);
  justify-content: flex-start;
}
.home-container276 {
  flex: 0 0 auto;
  width: 100%;
  height: 954px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container277 {
  width: 966px;
  height: 183px;
  display: flex;
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  justify-content: flex-start;
}
.home-container278 {
  flex: 0 0 auto;
  width: auto;
  height: 100px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  margin-left: 0px;
  justify-content: center;
}
.home-container279 {
  flex: 0 0 auto;
  width: 42px;
  height: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-icon30 {
  fill: var(--dl-color-primary-submarine);
  width: 100%;
  height: 100%;
}
.home-heading3 {
  color: rgb(234, 225, 213);
  width: 416px;
  font-size: 70px;
  max-width: 790px;
  align-self: center;
  font-style: normal;
  text-align: left;
  transition: 0.3s;
  font-family: Exo 2;
  font-weight: 500;
}
.home-heading3:hover {
  color: var(--dl-color-primary-tea);
}
.home-text858 {
  font-family: Raleway;
}
.home-text859 {
  font-size: inherit;
}
.home-container280 {
  flex: 0 0 auto;
  width: auto;
  height: 637px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.home-container281 {
  flex: 0 0 auto;
  width: 50%;
  height: 637px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text860 {
  width: 100%;
  height: 100%;
  font-size: 18px;
  align-self: flex-start;
  text-align: left;
  font-family: "Source Code Pro";
  font-weight: 300;
  line-height: 1.2;
  padding-left: var(--dl-space-space-sixunits);
}
.home-text861 {
  color: var(--dl-color-primary-mygreen);
  font-weight: 700;
}
.home-text863 {
  color: var(--dl-color-primary-whiteish);
}
.home-text864 {
  color: var(--dl-color-primary-whiteish);
}
.home-text865 {
  color: var(--dl-color-primary-whiteish);
}
.home-text866 {
  color: var(--dl-color-primary-whiteish);
}
.home-text867 {
  color: var(--dl-color-primary-whiteish);
}
.home-text868 {
  color: var(--dl-color-primary-whiteish);
}
.home-text869 {
  color: var(--dl-color-primary-whiteish);
}
.home-text870 {
  color: var(--dl-color-primary-whiteish);
}
.home-text871 {
  color: var(--dl-color-primary-whiteish);
}
.home-text872 {
  font-style: normal;
}
.home-text873 {
  font-style: normal;
}
.home-text874 {
  color: var(--dl-color-primary-mygreen);
  font-size: 20px;
  font-weight: 500;
}
.home-text875 {
  font-style: normal;
}
.home-text876 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text877 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text878 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text879 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text880 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text881 {
  color: var(--dl-color-primary-whiteish);
}
.home-text882 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text883 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text884 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text885 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text886 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text887 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text888 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text889 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text890 {
  font-style: normal;
}
.home-text891 {
  font-style: normal;
}
.home-text892 {
  color: var(--dl-color-primary-mygreen);
  font-size: 20px;
  font-weight: 500;
}
.home-text893 {
  font-style: normal;
}
.home-text894 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text895 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text896 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text897 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text898 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text899 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text900 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text901 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text902 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text903 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text904 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text905 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text906 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text907 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text908 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text909 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text910 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text911 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text912 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text913 {
  font-style: normal;
}
.home-text914 {
  color: var(--dl-color-primary-mygreen);
  width: 100%;
  height: 100%;
  font-size: 18px;
  align-self: flex-start;
  font-family: "Source Code Pro";
  font-weight: 300;
  line-height: 1.2;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-sixunits);
}
.home-text915 {
  font-weight: 700;
}
.home-text917 {
  color: var(--dl-color-primary-whiteish);
}
.home-text918 {
  color: var(--dl-color-primary-whiteish);
}
.home-text919 {
  color: var(--dl-color-primary-whiteish);
}
.home-text920 {
  color: var(--dl-color-primary-whiteish);
}
.home-text921 {
  color: var(--dl-color-primary-whiteish);
}
.home-text922 {
  color: var(--dl-color-primary-whiteish);
}
.home-text923 {
  color: var(--dl-color-primary-whiteish);
}
.home-text924 {
  color: var(--dl-color-primary-whiteish);
}
.home-text925 {
  color: var(--dl-color-primary-whiteish);
}
.home-text929 {
  font-weight: 700;
}
.home-text931 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text932 {
  color: var(--dl-color-primary-whiteish);
}
.home-text933 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text934 {
  color: var(--dl-color-primary-whiteish);
}
.home-text935 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text936 {
  color: var(--dl-color-primary-whiteish);
}
.home-text937 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text938 {
  color: var(--dl-color-primary-whiteish);
}
.home-text939 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text942 {
  font-weight: 700;
}
.home-text944 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text945 {
  color: var(--dl-color-primary-whiteish);
}
.home-text946 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text947 {
  color: var(--dl-color-primary-whiteish);
}
.home-text948 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text951 {
  font-weight: 700;
}
.home-text953 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-text954 {
  color: var(--dl-color-primary-whiteish);
  font-style: normal;
}
.home-what-i-do-mobile {
  width: 100%;
  height: 669px;
  display: none;
  align-items: flex-start;
  padding-left: calc(15px + 1.5vw);
  padding-right: calc(15px + 1.5vw);
  justify-content: flex-start;
}
.home-container282 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.home-container283 {
  flex: 0 0 auto;
  width: auto;
  height: 100px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  margin-left: 0px;
  justify-content: center;
}
.home-container284 {
  flex: 0 0 auto;
  width: 42px;
  height: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-icon32 {
  fill: var(--dl-color-primary-submarine);
  width: 100%;
  height: 100%;
}
.home-heading4 {
  color: rgb(234, 225, 213);
  width: 416px;
  font-size: 70px;
  max-width: 790px;
  align-self: center;
  font-style: normal;
  text-align: left;
  transition: 0.3s;
  font-family: Exo 2;
  font-weight: 500;
}
.home-heading4:hover {
  color: var(--dl-color-primary-tea);
}
.home-text956 {
  font-family: Raleway;
}
.home-container285 {
  flex: 0 0 auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-blog1 {
  width: 100%;
  height: 131px;
  display: none;
  align-items: flex-start;
}
.home-heading5 {
  color: rgb(234, 225, 213);
  width: 1671px;
  font-size: 100px;
  max-width: 790px;
  align-self: center;
  font-style: normal;
  text-align: center;
  font-family: Exo 2;
  font-weight: 500;
  padding-left: var(--dl-space-space-sixunits);
}
.home-text958 {
  font-size: 70px;
}
.home-text959 {
  color: var(--dl-color-primary-submarine);
  font-size: 70px;
}
.home-text960 {
  font-size: 70px;
}
.home-blog2 {
  width: 1601px;
  height: 763px;
  display: none;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  justify-content: space-between;
}
.home-container286 {
  width: 486px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.home-container287 {
  width: 502px;
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.home-container288 {
  width: 452px;
  height: 550px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-contact {
  gap: var(--dl-space-space-fourunits);
  width: 755px;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-end;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.home-content1 {
  gap: var(--dl-space-space-threeunits);
  width: 564px;
  height: 355px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  background-image: linear-gradient(225deg, rgb(47, 64, 63) 15.00%,rgb(29, 54, 57) 100.00%);
}
.home-image200 {
  top: 89px;
  left: 217px;
  width: 324px;
  height: 324px;
  opacity: 0.1;
  z-index: 2;
  position: absolute;
  align-self: center;
  object-fit: cover;
}
.home-container289 {
  width: 584px;
  height: 314px;
  display: flex;
  position: relative;
  align-items: center;
  padding-right: var(--dl-space-space-fourunits);
  justify-content: center;
}
.home-text962 {
  top: 120px;
  left: 378px;
  color: var(--dl-color-primary-whiteish);
  position: absolute;
  align-self: flex-start;
  font-style: normal;
  text-align: left;
  font-family: Raleway;
  font-weight: 300;
}
.home-text966 {
  top: 173px;
  left: 67px;
  color: var(--dl-color-primary-whiteish);
  z-index: 1000;
  position: absolute;
  align-self: flex-start;
  font-style: normal;
  text-align: left;
  font-family: Raleway;
  font-weight: 300;
}
.home-text967 {
  font-size: 20px;
}
.home-icon34 {
  top: 91px;
  fill: var(--dl-color-primary-submarine);
  left: 75px;
  width: 17px;
  height: 18px;
  position: absolute;
}
.home-link3 {
  display: contents;
}
.home-icon36 {
  top: 167px;
  fill: var(--dl-color-primary-whiteish);
  left: 374px;
  color: var(--dl-color-primary-tea);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  z-index: 1000;
  position: absolute;
  align-self: center;
  transition: 0.3s;
  text-decoration: none;
}
.home-icon36:hover {
  fill: var(--dl-color-primary-tea);
}
.home-link4 {
  display: contents;
}
.home-icon38 {
  top: 167px;
  fill: var(--dl-color-primary-whiteish);
  left: 425px;
  color: var(--dl-color-primary-tea);
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  z-index: 1000;
  position: absolute;
  align-self: center;
  transition: 0.3s;
  text-decoration: none;
}
.home-icon38:hover {
  fill: var(--dl-color-primary-tea);
}
.home-text971 {
  top: 89px;
  left: 79px;
  color: var(--dl-color-primary-whiteish);
  position: absolute;
  align-self: flex-start;
  font-style: normal;
  text-align: right;
  font-family: Raleway;
  font-weight: 300;
}
.home-text972 {
  font-size: 20px;
}
.home-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-content2 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  border-top-width: 1px;
}
.home-bottom {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-text976 {
  color: rgb(124, 124, 128);
}
.home-button {
  display: flex;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: 0px;
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-primary-bazaar);
}
.home-image201 {
  width: 21px;
  object-fit: cover;
}
.home-container291 {
  display: contents;
}
@media(max-width: 1600px) {
  .home-header {
    height: 60px;
    position: relative;
  }
  .home-text100 {
    font-size: 24px;
    font-style: normal;
    font-family: Raleway;
    font-weight: 600;
    text-decoration: none;
  }
  .home-text101 {
    color: var(--dl-color-primary-tea);
  }
  .home-text102 {
    color: var(--dl-color-primary-submarine);
  }
  .home-slider-container1 {
    height: 404px;
    position: relative;
  }
  .home-slide10 {
    flex: initial;
    background-color: transparent;
  }
  .home-image101 {
    width: 300px;
    height: 300px;
  }
  .home-slide11 {
    flex: initial;
    background-color: transparent;
  }
  .home-image102 {
    width: 300px;
    height: 300px;
  }
  .home-slide12 {
    flex: initial;
    background-color: transparent;
  }
  .home-image103 {
    width: 300px;
    height: 300px;
  }
  .home-slide13 {
    flex: initial;
    background-color: #0049ff;
  }
  .home-image104 {
    width: 300px;
    height: 300px;
  }
  .home-slide14 {
    flex: initial;
    background-color: transparent;
  }
  .home-image105 {
    width: 300px;
    height: 300px;
  }
  .home-slide15 {
    flex: initial;
    background-color: transparent;
  }
  .home-image106 {
    width: 300px;
    height: 300px;
  }
  .home-slide16 {
    flex: initial;
    background-color: transparent;
  }
  .home-image107 {
    width: 300px;
    height: 300px;
  }
  .home-slide17 {
    flex: initial;
    background-color: #0049ff;
  }
  .home-image108 {
    width: 300px;
    height: 300px;
  }
  .home-slide18 {
    flex: initial;
    background-color: transparent;
  }
  .home-image109 {
    width: 300px;
    height: 300px;
  }
  .home-slide19 {
    flex: initial;
    background-color: transparent;
  }
  .home-image110 {
    width: 300px;
    height: 300px;
  }
  .home-slide20 {
    flex: initial;
    background-color: transparent;
  }
  .home-image111 {
    width: 300px;
    height: 300px;
  }
  .home-slide21 {
    flex: initial;
    background-color: transparent;
  }
  .home-image112 {
    width: 300px;
    height: 300px;
  }
  .home-slide22 {
    flex: initial;
    background-color: #0049ff;
  }
  .home-image113 {
    width: 300px;
    height: 300px;
  }
  .home-slide23 {
    flex: 1;
    width: 100%;
    height: 100%;
    background-color: transparent;
  }
  .home-image114 {
    width: 300px;
    height: 300px;
  }
  .home-slide24 {
    flex: initial;
    background-color: transparent;
  }
  .home-image115 {
    width: 300px;
    height: 300px;
  }
  .home-slide25 {
    flex: initial;
    background-color: #0049ff;
  }
  .home-image116 {
    width: 300px;
    height: 300px;
  }
  .home-slide26 {
    flex: 1;
    background-color: transparent;
  }
  .home-image117 {
    width: 300px;
    height: 300px;
  }
  .home-slide27 {
    flex: initial;
    background-color: transparent;
  }
  .home-image118 {
    width: 300px;
    height: 300px;
  }
  .home-slide28 {
    flex: 1;
    background-color: transparent;
  }
  .home-image119 {
    width: 300px;
    height: 300px;
  }
  .home-controls1 {
    height: 97px;
  }
  .home-discography-mobile {
    height: fit-content;
  }
  .home-text320 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text321 {
    color: var(--dl-color-primary-whiteish);
    font-size: 16px;
    font-style: italic;
    font-family: Inter;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .home-text322 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text330 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text331 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text332 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text335 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text336 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text337 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text340 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text341 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text342 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text345 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text346 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text347 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text350 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text351 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text352 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text355 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text356 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text357 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text360 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text361 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text362 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text365 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text366 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text367 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text370 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text373 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text374 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text377 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text380 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text381 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text384 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text387 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text388 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text391 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text394 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text395 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text399 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text402 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text403 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text406 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text409 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text410 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text413 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text416 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text417 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text420 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text423 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text424 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text427 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text430 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text431 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text434 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text437 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text438 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text441 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text444 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text445 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text448 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text451 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text452 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text455 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text456 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text457 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text460 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text463 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text464 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text467 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text470 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text471 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-discography-desktop {
    height: 5732px;
  }
  .home-text494 {
    color: var(--dl-color-primary-whiteish);
    font-style: italic;
  }
  .home-text498 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text499 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text500 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text504 {
    color: var(--dl-color-primary-whiteish);
    font-style: italic;
  }
  .home-text505 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text509 {
    color: var(--dl-color-primary-whiteish);
    font-style: italic;
  }
  .home-text510 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text519 {
    color: var(--dl-color-primary-whiteish);
    font-size: 16px;
    font-style: italic;
    font-family: Inter;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .home-text520 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text523 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text524 {
    color: var(--dl-color-primary-whiteish);
    font-size: 16px;
    font-style: italic;
    font-family: Inter;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .home-text525 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text538 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text539 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text540 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text544 {
    color: var(--dl-color-primary-whiteish);
    font-style: italic;
  }
  .home-text545 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text548 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text549 {
    color: var(--dl-color-primary-whiteish);
    font-style: italic;
  }
  .home-text550 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text553 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text554 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text555 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text558 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text559 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text560 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text564 {
    color: var(--dl-color-primary-whiteish);
    font-style: italic;
  }
  .home-text565 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text568 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text571 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text572 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text575 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text576 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text577 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text581 {
    color: var(--dl-color-primary-whiteish);
    font-style: italic;
  }
  .home-text582 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text586 {
    color: var(--dl-color-primary-whiteish);
    font-style: italic;
  }
  .home-text587 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text591 {
    color: var(--dl-color-primary-whiteish);
    font-style: italic;
  }
  .home-text592 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text595 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text596 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text597 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text601 {
    color: var(--dl-color-primary-whiteish);
    font-size: 16px;
    font-style: italic;
    font-family: Inter;
    font-weight: 400;
    line-height: 1.15;
    text-transform: none;
    text-decoration: none;
  }
  .home-text602 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text605 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text606 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text607 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text610 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text611 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text612 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text615 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text618 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text619 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text622 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text623 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text624 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text627 {
    color: var(--dl-color-primary-whiteish);
    font-weight: 700;
  }
  .home-text628 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text629 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text632 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text635 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text636 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text639 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text642 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text643 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text647 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text650 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text651 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text654 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text657 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text658 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text661 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text664 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text665 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text668 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text671 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text672 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text675 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text678 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text679 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text682 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text685 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text686 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text689 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text692 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text693 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text696 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text699 {
    color: var(--dl-color-primary-whiteish);
    font-style: italic;
  }
  .home-text700 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text703 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text706 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text707 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text710 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text713 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text714 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text717 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text718 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text719 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text722 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text725 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text726 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text729 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text730 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text731 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text734 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text737 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text738 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text741 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text744 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text745 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-container267 {
    width: 1119px;
  }
  .home-container268 {
    width: 611px;
    margin-left: var(--dl-space-space-oneandhalfunits);
  }
  .home-caption {
    color: var(--dl-color-primary-whiteish);
    width: 519px;
    height: 487px;
    font-size: 16px;
    line-height: 21px;
  }
  .home-text753 {
    font-weight: 700;
    line-height: 21px;
  }
  .home-text754 {
    line-height: 21px;
  }
  .home-text755 {
    line-height: 21px;
  }
  .home-text756 {
    font-weight: 700;
    line-height: 21px;
  }
  .home-text757 {
    line-height: 21px;
  }
  .home-text758 {
    line-height: 21px;
  }
  .home-text759 {
    line-height: 21px;
  }
  .home-text760 {
    line-height: 21px;
  }
  .home-text761 {
    line-height: 21px;
  }
  .home-text762 {
    font-weight: 700;
    line-height: 21px;
  }
  .home-text763 {
    font-weight: 700;
    line-height: 21px;
  }
  .home-text764 {
    font-weight: 700;
    line-height: 21px;
  }
  .home-text765 {
    font-weight: 700;
    line-height: 21px;
  }
  .home-text766 {
    font-weight: 700;
    line-height: 21px;
  }
  .home-text767 {
    font-weight: 700;
    line-height: 21px;
  }
  .home-text768 {
    font-weight: 700;
    line-height: 21px;
  }
  .home-text769 {
    line-height: 21px;
  }
  .home-text770 {
    line-height: 21px;
  }
  .home-text771 {
    line-height: 21px;
  }
  .home-text772 {
    font-weight: 700;
    line-height: 21px;
  }
  .home-text773 {
    font-weight: 700;
    line-height: 21px;
  }
  .home-text774 {
    font-weight: 700;
    line-height: 21px;
  }
  .home-text775 {
    line-height: 21px;
  }
  .home-text776 {
    font-weight: 700;
    line-height: 21px;
  }
  .home-text777 {
    font-weight: 700;
    line-height: 21px;
  }
  .home-text778 {
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }
  .home-text779 {
    line-height: 21px;
  }
  .home-text780 {
    line-height: 21px;
  }
  .home-text781 {
    line-height: 21px;
  }
  .home-container273 {
    align-self: center;
  }
  .home-slider-container2 {
    height: 531px;
    position: relative;
  }
  .home-slide29 {
    flex: initial;
    background-color: transparent;
  }
  .home-image195 {
    width: 300px;
    height: 300px;
  }
  .home-slide30 {
    flex: initial;
    background-color: #0049ff;
  }
  .home-image196 {
    width: 300px;
    height: 300px;
  }
  .home-slide31 {
    flex: 1;
    width: 100%;
    height: 100%;
    background-color: transparent;
  }
  .home-image197 {
    width: 300px;
    height: 300px;
  }
  .home-slide32 {
    flex: 1;
    background-color: transparent;
  }
  .home-image198 {
    width: 300px;
    height: 300px;
  }
  .home-slide33 {
    flex: 1;
    background-color: transparent;
  }
  .home-image199 {
    width: 300px;
    height: 300px;
  }
  .home-controls2 {
    height: 97px;
  }
  .home-text914 {
    color: var(--dl-color-primary-mygreen);
  }
  .home-text915 {
    font-style: normal;
  }
  .home-text916 {
    font-style: normal;
  }
  .home-text917 {
    font-style: normal;
  }
  .home-text918 {
    font-style: normal;
  }
  .home-text919 {
    font-style: normal;
  }
  .home-text920 {
    font-style: normal;
  }
  .home-text921 {
    font-style: normal;
  }
  .home-text922 {
    font-style: normal;
  }
  .home-text923 {
    font-style: normal;
  }
  .home-text924 {
    font-style: normal;
  }
  .home-text925 {
    font-style: normal;
  }
  .home-text926 {
    font-style: normal;
  }
  .home-text927 {
    font-style: normal;
  }
  .home-text928 {
    font-style: normal;
  }
  .home-text929 {
    font-weight: 500;
  }
  .home-text930 {
    font-style: normal;
  }
  .home-text931 {
    color: #eae1d5;
    font-style: normal;
  }
  .home-text932 {
    color: #eae1d5;
    font-style: normal;
  }
  .home-text933 {
    color: #eae1d5;
    font-style: normal;
  }
  .home-text934 {
    color: #eae1d5;
    font-style: normal;
  }
  .home-text935 {
    color: #eae1d5;
    font-style: normal;
  }
  .home-text936 {
    color: #eae1d5;
    font-style: normal;
  }
  .home-text937 {
    color: #eae1d5;
    font-style: normal;
  }
  .home-text938 {
    color: #eae1d5;
    font-style: normal;
  }
  .home-text939 {
    color: #eae1d5;
    font-style: normal;
  }
  .home-text940 {
    font-style: normal;
  }
  .home-text941 {
    font-style: normal;
  }
  .home-text942 {
    font-weight: 500;
  }
  .home-text943 {
    font-style: normal;
  }
  .home-text944 {
    color: #eae1d5;
    font-style: normal;
  }
  .home-text945 {
    color: #eae1d5;
    font-style: normal;
  }
  .home-text946 {
    color: #eae1d5;
    font-style: normal;
  }
  .home-text947 {
    color: #eae1d5;
    font-style: normal;
  }
  .home-text948 {
    color: #eae1d5;
    font-style: normal;
  }
  .home-text949 {
    font-style: normal;
  }
  .home-text950 {
    font-style: normal;
  }
  .home-text951 {
    font-weight: 500;
  }
  .home-text952 {
    color: #eae1d5;
    font-style: normal;
  }
  .home-text953 {
    color: #eae1d5;
    font-style: normal;
  }
  .home-text954 {
    color: #eae1d5;
    font-style: normal;
  }
  .home-text955 {
    font-style: normal;
  }
  .home-content1 {
    position: relative;
  }
  .home-image200 {
    top: -8px;
    left: 88px;
    width: 404px;
    height: 342px;
  }
  .home-text971 {
    color: var(--dl-color-primary-whiteish);
    font-style: normal;
    font-family: Raleway;
    font-weight: 300;
  }
  .home-text972 {
    font-size: 20px;
    font-weight: 700;
  }
  .home-text976 {
    color: rgb(124, 124, 128);
  }
}
@media(max-width: 1200px) {
  .home-text457 {
    color: rgb(249, 242, 242);
  }
  .home-text464 {
    color: rgb(249, 242, 242);
  }
  .home-discography-desktop {
    height: 4508px;
  }
  .home-container161 {
    height: 3286px;
  }
  .home-text559 {
    color: var(--dl-color-primary-whiteish);
    font-style: italic;
  }
  .home-text560 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text564 {
    color: var(--dl-color-primary-whiteish);
    font-style: italic;
  }
  .home-text565 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-text696 {
    color: rgb(249, 242, 242);
  }
  .home-text699 {
    color: rgb(249, 242, 242);
    font-style: italic;
  }
  .home-text700 {
    color: rgb(249, 242, 242);
  }
  .home-text713 {
    color: rgb(249, 242, 242);
    font-style: italic;
  }
  .home-text714 {
    color: rgb(249, 242, 242);
  }
  .home-text731 {
    color: rgb(249, 242, 242);
  }
  .home-text738 {
    color: rgb(249, 242, 242);
  }
}
@media(max-width: 991px) {
  .home-hero {
    padding-left: var(--dl-space-space-oneandhalfunits);
  }
  .home-slider1 {
    gap: var(--dl-space-space-twounits);
  }
  .home-image101 {
    width: 100%;
    height: 120px;
  }
  .home-image102 {
    width: 100%;
    height: 120px;
  }
  .home-image103 {
    width: 100%;
    height: 120px;
  }
  .home-image104 {
    width: 100%;
    height: 120px;
  }
  .home-image105 {
    width: 100%;
    height: 120px;
  }
  .home-image106 {
    width: 100%;
    height: 120px;
  }
  .home-image107 {
    width: 100%;
    height: 120px;
  }
  .home-image108 {
    width: 100%;
    height: 120px;
  }
  .home-image109 {
    width: 100%;
    height: 120px;
  }
  .home-image110 {
    width: 100%;
    height: 120px;
  }
  .home-image111 {
    width: 100%;
    height: 120px;
  }
  .home-image112 {
    width: 100%;
    height: 120px;
  }
  .home-image113 {
    width: 100%;
    height: 120px;
  }
  .home-text242 {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .home-image114 {
    width: 100%;
    height: 120px;
  }
  .home-image115 {
    width: 100%;
    height: 120px;
  }
  .home-image116 {
    width: 100%;
    height: 120px;
  }
  .home-image117 {
    width: 100%;
    height: 120px;
  }
  .home-image118 {
    width: 100%;
    height: 120px;
  }
  .home-image119 {
    width: 100%;
    height: 120px;
  }
  .home-discography-desktop {
    height: 2872px;
  }
  .home-text494 {
    color: var(--dl-color-primary-whiteish);
    font-style: italic;
  }
  .home-text514 {
    color: var(--dl-color-primary-whiteish);
    font-style: italic;
  }
  .home-text530 {
    color: var(--dl-color-primary-whiteish);
  }
  .home-slider2 {
    gap: var(--dl-space-space-twounits);
  }
  .home-image195 {
    width: 100%;
    height: 120px;
  }
  .home-image196 {
    width: 100%;
    height: 120px;
  }
  .home-image197 {
    width: 100%;
    height: 120px;
  }
  .home-image198 {
    width: 100%;
    height: 120px;
  }
  .home-image199 {
    width: 100%;
    height: 120px;
  }
  .home-blog2 {
    flex-direction: column;
  }
  .home-container286 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-container287 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-content1 {
    gap: var(--dl-space-space-twounits);
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-content2 {
    border-top-width: 0px;
  }
}
@media(max-width: 767px) {
  .home-nav1 {
    display: none;
  }
  .home-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-discography-og {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-slider1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-previous1 {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-next1 {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-discography-mobile {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-discography-desktop {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-heading1 {
    font-size: 60px;
  }
  .home-about {
    align-items: flex-start;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-heading2 {
    font-size: 60px;
  }
  .home-caption {
    font-size: 16px;
    line-height: 21px;
  }
  .home-discography {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-slider2 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-previous2 {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-next2 {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-heading3 {
    font-size: 60px;
  }
  .home-heading4 {
    font-size: 60px;
  }
  .home-heading5 {
    font-size: 60px;
  }
  .home-blog2 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-contact {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-content1 {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-text966 {
    z-index: 800;
  }
  .home-text971 {
    z-index: 800;
  }
  .home-footer {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .home-hero {
    height: 53px;
    padding-left: 0px;
  }
  .home-header {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-text100 {
    font-family: Raleway;
    font-weight: 600;
    margin-left: var(--dl-space-space-halfunit);
  }
  .home-text101 {
    color: var(--dl-color-primary-tea);
    font-style: normal;
  }
  .home-text102 {
    color: var(--dl-color-primary-submarine);
    font-style: normal;
  }
  .home-burger-menu {
    background-color: var(--dl-color-primary-tea);
  }
  .home-mobile-menu {
    display: none;
    padding: 16px;
    background-color: var(--dl-color-primary-cleanslate);
  }
  .home-nav2 {
    height: 516px;
  }
  .home-icon12 {
    fill: var(--dl-color-primary-whiteish);
  }
  .home-mobile-photo {
    height: 502px;
    display: none;
    margin-bottom: var(--dl-space-space-sixunits);
  }
  .home-image100 {
    width: 337px;
    height: 477px;
  }
  .home-discography-og {
    height: 296px;
    display: none;
  }
  .home-slider-container1 {
    width: 479px;
    height: 264px;
  }
  .home-slider-track-container1 {
    width: 479px;
  }
  .home-slider-track1 {
    width: 479px;
    height: 150px;
  }
  .home-image101 {
    width: 150px;
    height: 150px;
  }
  .home-text103 {
    font-size: 12px;
  }
  .home-image102 {
    width: 150px;
    height: 150px;
  }
  .home-text115 {
    font-size: 12px;
  }
  .home-image103 {
    width: 150px;
    height: 150px;
  }
  .home-image104 {
    width: 150px;
    height: 150px;
  }
  .home-text126 {
    color: rgba(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    font-size: 12px;
    line-height: 1.5;
  }
  .home-text127 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text128 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text129 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text130 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text131 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text132 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text133 {
    font-style: normal;
    font-family: Source Code Pro;
    font-weight: 300;
    text-transform: none;
    text-decoration: none;
  }
  .home-text136 {
    font-size: 12px;
  }
  .home-image105 {
    width: 150px;
    height: 150px;
  }
  .home-text146 {
    font-size: 12px;
  }
  .home-image106 {
    width: 150px;
    height: 150px;
  }
  .home-text157 {
    font-size: 12px;
  }
  .home-image107 {
    width: 150px;
    height: 150px;
  }
  .home-image108 {
    width: 150px;
    height: 150px;
  }
  .home-text168 {
    color: rgba(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    font-size: 12px;
    line-height: 1.5;
  }
  .home-text169 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text170 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text171 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text172 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text173 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text174 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text175 {
    font-style: normal;
    font-family: Source Code Pro;
    font-weight: 300;
    text-transform: none;
    text-decoration: none;
  }
  .home-text178 {
    font-size: 12px;
  }
  .home-image109 {
    width: 150px;
    height: 150px;
  }
  .home-text190 {
    font-size: 12px;
  }
  .home-image110 {
    width: 150px;
    height: 150px;
  }
  .home-text202 {
    font-size: 12px;
  }
  .home-image111 {
    width: 150px;
    height: 150px;
  }
  .home-text217 {
    font-size: 12px;
  }
  .home-image112 {
    width: 150px;
    height: 150px;
  }
  .home-image113 {
    width: 150px;
    height: 150px;
  }
  .home-text230 {
    color: rgba(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    font-size: 12px;
    line-height: 1.5;
  }
  .home-text231 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text232 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text233 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text234 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text235 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text236 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text237 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text238 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text239 {
    font-style: normal;
    font-family: Source Code Pro;
    font-weight: 300;
    text-transform: none;
    text-decoration: none;
  }
  .home-text242 {
    width: 100%;
    height: 100%;
    font-size: 12px;
  }
  .home-image114 {
    width: 150px;
    height: 150px;
  }
  .home-text252 {
    font-size: 12px;
  }
  .home-image115 {
    width: 150px;
    height: 150px;
  }
  .home-image116 {
    width: 150px;
    height: 150px;
  }
  .home-text262 {
    color: rgba(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    font-size: 12px;
    line-height: 1.5;
  }
  .home-text263 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text264 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text265 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text266 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text267 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text268 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text269 {
    font-style: normal;
    font-family: Source Code Pro;
    font-weight: 300;
    text-transform: none;
    text-decoration: none;
  }
  .home-text272 {
    width: 100%;
    height: 100%;
    font-size: 12px;
  }
  .home-image117 {
    width: 150px;
    height: 150px;
  }
  .home-text282 {
    font-size: 12px;
  }
  .home-image118 {
    width: 150px;
    height: 150px;
  }
  .home-image119 {
    width: 150px;
    height: 150px;
  }
  .home-text293 {
    color: rgba(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    font-size: 12px;
    line-height: 1.5;
  }
  .home-text294 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text295 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text296 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text297 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text298 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text299 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text300 {
    font-style: normal;
    font-family: Source Code Pro;
    font-weight: 300;
    text-transform: none;
    text-decoration: none;
  }
  .home-discography-mobile {
    width: 100%;
    height: fit-content;
    display: flex;
  }
  .home-container104 {
    width: 100%;
    height: 100%;
    grid-template-rows: auto;
    grid-template-columns: 50vw 50vw;
  }
  .home-discography-desktop {
    height: 1354px;
    display: none;
  }
  .home-spotify-player {
    width: 100%;
    height: 450px;
    margin-top: var(--dl-space-space-threeunits);
    padding-top: 0px;
  }
  .home-container256 {
    margin-top: 0px;
  }
  .home-container257 {
    width: 100%;
    margin-left: var(--dl-space-space-unit);
  }
  .home-heading1 {
    color: rgb(234, 225, 213);
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
  }
  .home-text748 {
    font-family: Raleway;
  }
  .home-container259 {
    width: 403px;
  }
  .home-live-sessions {
    width: 100%;
    display: none;
  }
  .home-container261 {
    width: 479px;
  }
  .home-container262 {
    width: 404px;
    height: 278px;
  }
  .home-about {
    width: 100%;
    height: 723px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .home-container267 {
    width: 100%;
    height: 516px;
    padding: 0px;
  }
  .home-container268 {
    flex: 1;
    width: 479px;
    height: 549px;
    position: relative;
    margin-top: 0px;
    margin-right: var(--dl-space-space-unit);
    padding-left: calc(8px + 1.5vw);
    margin-bottom: 0px;
    padding-right: calc(8px + 1.5vw)x;
  }
  .home-container269 {
    margin-left: var(--dl-space-space-unit);
  }
  .home-icon20 {
    margin-left: var(--dl-space-space-unit);
  }
  .home-heading2 {
    font-size: 36px;
    margin-left: var(--dl-space-space-unit);
  }
  .home-caption {
    color: var(--dl-color-primary-whiteish);
    width: 366px;
    height: 341px;
    font-size: 16px;
    align-self: center;
    margin-top: 0px;
    line-height: 21px;
    margin-right: var(--dl-space-space-threeunits);
  }
  .home-container271 {
    display: none;
    padding-right: var(--dl-space-space-threeunits);
    justify-content: center;
  }
  .home-container272 {
    display: none;
  }
  .home-container273 {
    width: 337px;
    height: 477px;
  }
  .home-discography {
    height: 296px;
    display: none;
  }
  .home-slider-container2 {
    width: 479px;
    height: 264px;
  }
  .home-slider-track-container2 {
    width: 479px;
  }
  .home-slider-track2 {
    width: 479px;
    height: 150px;
  }
  .home-text815 {
    font-size: 12px;
  }
  .home-image195 {
    width: 150px;
    height: 150px;
  }
  .home-image196 {
    width: 150px;
    height: 150px;
  }
  .home-text827 {
    color: rgba(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    font-size: 12px;
    line-height: 1.5;
  }
  .home-text828 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text829 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text830 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text831 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text832 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text833 {
    font-family: Raleway;
    text-transform: none;
    text-decoration: none;
  }
  .home-text834 {
    font-style: normal;
    font-family: Source Code Pro;
    font-weight: 300;
    text-transform: none;
    text-decoration: none;
  }
  .home-text837 {
    width: 100%;
    height: 100%;
    font-size: 12px;
  }
  .home-image197 {
    width: 150px;
    height: 150px;
  }
  .home-text847 {
    color: rgba(255, 255, 255, 0);
    width: 100%;
    height: 100%;
    font-size: 12px;
    line-height: 1.5;
  }
  .home-image198 {
    width: 150px;
    height: 150px;
  }
  .home-image199 {
    width: 150px;
    height: 150px;
  }
  .home-text848 {
    font-size: smaller;
  }
  .home-what-i-do-web {
    display: none;
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
  }
  .home-container278 {
    margin-left: var(--dl-space-space-halfunit);
  }
  .home-heading3 {
    font-size: 36px;
  }
  .home-what-i-do-mobile {
    height: 1031px;
    display: none;
    margin-top: var(--dl-space-space-fiveunits);
    align-items: flex-start;
    padding-top: calc(15px + 1.5vw);
    padding-left: calc(8px + 1.5vw);
    padding-right: calc(8px + 1.5vw);
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-container283 {
    width: 100%;
    margin-left: var(--dl-space-space-unit);
  }
  .home-heading4 {
    color: rgb(234, 225, 213);
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
  }
  .home-text956 {
    font-family: Raleway;
  }
  .home-text957 {
    font-size: inherit;
  }
  .home-container285 {
    height: 905px;
  }
  .home-blog1 {
    display: none;
  }
  .home-heading5 {
    font-size: 36px;
  }
  .home-blog2 {
    display: none;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-contact {
    width: 100%;
  }
  .home-content1 {
    width: 387px;
    height: 242px;
    position: relative;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .home-image200 {
    top: 10px;
    left: 53px;
    width: 282px;
    height: 222px;
    margin: auto;
    z-index: 0;
    position: absolute;
  }
  .home-container289 {
    width: 375px;
    height: 100%;
    padding-right: 0px;
    justify-content: center;
  }
  .home-text962 {
    top: 127px;
    left: 226px;
    position: absolute;
    align-self: flex-start;
    text-align: left;
  }
  .home-text966 {
    top: 163px;
    left: 31px;
  }
  .home-icon34 {
    top: 70px;
    left: 33px;
  }
  .home-icon36 {
    left: 275px;
    bottom: 34px;
    z-index: 800;
    position: absolute;
    align-self: flex-start;
  }
  .home-icon38 {
    left: 228px;
    bottom: 27px;
    z-index: 800;
    position: absolute;
    align-self: flex-end;
  }
  .home-text971 {
    top: 67px;
    left: 36px;
  }
  .home-footer {
    height: 331px;
  }
  .home-content2 {
    height: 406px;
  }
  .home-bottom {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: center;
  }
  .home-button {
    justify-content: center;
  }
}
