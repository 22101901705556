.navigation-links-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.navigation-links-link1 {
  color: var(--dl-color-primary-tea);
  font-style: normal;
  font-family: Raleway;
  font-weight: 300;
  text-decoration: none;
}
.navigation-links-link2 {
  color: var(--dl-color-primary-tea);
  font-style: normal;
  font-family: Raleway;
  font-weight: 300;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.navigation-links-link3 {
  color: var(--dl-color-primary-tea);
  font-style: normal;
  font-family: Raleway;
  font-weight: 300;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.navigation-links-link4 {
  color: var(--dl-color-primary-tea);
  font-style: normal;
  font-family: Raleway;
  font-weight: 300;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.navigation-links-link5 {
  color: var(--dl-color-primary-tea);
  display: none;
  font-style: normal;
  font-family: Raleway;
  font-weight: 300;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}








.navigation-linksroot-class-name17 {
  margin-right: var(--dl-space-space-sixunits);
}

@media(max-width: 767px) {
  .navigation-links-nav {
    align-items: flex-start;
    flex-direction: column;
  }
  .navigation-links-link1 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .navigation-links-link2 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .navigation-links-link3 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .navigation-links-link4 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .navigation-links-link5 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .navigation-links-link1 {
    font-size: 50px;
  }
  .navigation-links-link2 {
    font-size: 50px;
  }
  .navigation-links-link3 {
    font-size: 50px;
  }
  .navigation-links-link4 {
    font-size: 50px;
  }
  .navigation-linksroot-class-name18 {
    width: auto;
    align-self: center;
  }
}
